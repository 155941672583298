import blog from './blog'
import login from './login'
import user from './user'
import sys from './sys'

export default {
  blog,
  login,
  user,
  sys,
}
